<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
        <!-- 코드 그룹 -->
        <c-table
          ref="grpTable"
          title="LBL0003342"
          tableId="grpTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :columns="grpGrid.columns"
          :isExcelDown="false"
          :data="grpGrid.data"
          @rowClick="getCodeMst"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <!-- 검색 -->
            <c-btn label="LBLSEARCH" icon="search"  @btnClicked="getCodeGroup"/>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
        <!-- 코드 마스터 -->
        <c-table
          ref="mstTable"
          title="LBL0003340"
          tableId="mstTable"
          :columnSetting="false"
          :isFullScreen="false"
          :usePaging="false"
          :filtering="false"
          :columns="mstGrid.columns"
          :isExcelDown="false"
          :data="mstGrid.data"
          selection="multiple"
          rowKey="code"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
            <!-- 추가 -->
              <c-btn label="LBLADD" icon="add" @btnClicked="addrow" v-if="editable && isSelectGrp" />
            <!-- 삭제 -->
              <c-btn label="LBLREMOVE" icon="remove" @btnClicked="removeRow" v-if="editable && isSelectGrp" />
            <!-- 저장 -->
              <c-btn label="LBLSAVE" icon="save" @btnClicked="saveMst" v-if="editable && isSelectGrp" />
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <q-dialog v-model="prompt" persistent>
      <q-card style="min-width: 350px">
        <q-card-section>
          <!-- 코드 -->
          <div class="text-h6">{{$label('LBL0003341')}}</div>
        </q-card-section>

        <q-card-section class="q-pt-none">
          <q-input dense v-model="hiddenMstCd" autofocus @keyup.enter="setMst" />
        </q-card-section>

        <q-card-actions align="right" class="text-primary">
          <!-- 취소 -->
          <q-btn flat :label="$label('LBLCANCEL')" @click="cancelMst" />
          <!-- 추가 -->
          <q-btn flat :label="$label('LBLADD')" @click="setMst" />
        </q-card-actions>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>

import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'code-use-manager',

  data() {
    return {
      editable: true,
      prompt: false,
      searchParam: {
        srchDomainCd: null,
      },
      hiddenMstCd: '',
      isSelectGrp: false,
      selectedGroupCd: '',
      domainItems: [],
      domainlistUrl: '',
      grouplistUrl: '',
      mstSaveUrl: '',
      mstDeleteUrl: '',
      grpGrid: {
        columns: [
          {
            name: 'codeGrpCd',
            field: 'codeGrpCd',
            // 코드 그룹
            label: 'LBL0003342',
            align: 'center',
            style: 'width:50%',
            sortable: true,
          },
          {
            name: 'codeGrpNm',
            field: 'codeGrpNm',
            // 그룹명
            label: 'LBL0003343',
            style: 'width:50%',
            align: 'left',
            sortable: true,
          },
        ],
        data: [],
      },
      mstGrid: {
        columns: [
          {
            name: 'code',
            field: 'code',
            // 코드
            label: 'LBL0003341',
            align: 'center',
            style: 'width:150px',
            required: true,
            sortable: false,
          },
          {
            name: 'codeNameKr',
            field: 'codeNameKr',
            // 코드명
            label: 'LBL0003344',
            align: 'left',
            style: 'width:300px',
            type: 'text',
            required: true,
            sortable: false,
          },
          {
            name: 'attrVal1',
            field: 'attrVal1',
            // 추가속성1
            label: 'LBL0003345',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'attrVal2',
            field: 'attrVal2',
            // 추가속성2
            label: 'LBL0003346',
            align: 'left',
            type: 'text',
            sortable: false,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            // 사용여부
            label: 'LBLUSEFLAG',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'sortOrder',
            field: 'sortOrder',
            // 정렬순서
            label: 'LBLSORTORDER',
            style: 'width:60px',
            align: 'center',
            type: 'text',
            required: true,
            sortable: false,
          },
        ],
        data: [],
      },
      searchUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.grouplistUrl = selectConfig.sys.code.usegroup.list.url;
      this.mstlistUrl = selectConfig.sys.code.mst.list.url;
      this.mstSaveUrl = transactionConfig.sys.code.mst.save.url;
      this.mstDeleteUrl = transactionConfig.sys.code.mst.delete.url;
      this.getCodeGroup();
    },
    rowRemoveSelect() {
      var _table = document.getElementsByClassName('bg-light-blue-1');
      if (_table.length > 0) {
        for(let c = 0; c <= _table.length; c++) {
          _table[c].classList.remove('bg-light-blue-1');
        }
      }
    },
    getCodeGroup() {
      this.rowRemoveSelect();
      this.isSelectGrp = false;
      this.mstGrid.data = [];
      this.$http.url = this.grouplistUrl;
      this.$http.param = {
        srchSystemYn: 'N'
      }
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.grpGrid.data = _result.data;
      },
      () => {
      });
    },
    getCodeMst(row) {
      this.isSelectGrp = true;
      this.selectedGroupCd = row.codeGrpCd;
      this.$http.url = this.$format(this.mstlistUrl, this.selectedGroupCd);
      this.$http.type = 'GET';
      this.$http.request((_result) => {
        this.mstGrid.data = _result.data;
      },
      () => {
      });
    },
    saveMst() {
      if (this.$comm.validTable(this.mstGrid.columns, this.mstGrid.data)) {
        let saveData = this.mstGrid.data.filter( x => {
          return x.editFlag == 'C' || x.editFlag == 'U'
        });
        if(saveData.length > 0) {
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: 'MSGSAVE', // 저장하시겠습니까?
            type: 'info',
            // 확인 callback 함수
            confirmCallback: () => {
              this.$http.url = this.mstSaveUrl;
              this.$http.type = 'POST';
              this.$http.param = saveData;
              this.$http.request(() => {
                window.getApp.$emit('APP_REQUEST_SUCCESS');
                this.getCodeMst({codeGrpCd: saveData[0].codeGrpCd});
              });
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', /* 안내 */
            message: 'MSGNOCHANGEDATA', // 변경된 데이터가 없습니다.
            type: 'info', // success / info / warning / error
          });
        }
      }
    },
    addrow() {
      let cnt = this.mstGrid.data.length;
      this.prompt = true;
      if (cnt > 0) {
        let _code = this.mstGrid.data[cnt-1].code;
        if (_code.length == 10) {
          let _num = _code.replace(/[a-zA-Z]/g,'');
          let _eng = _code.replace(_num,'');
          this.hiddenMstCd = _eng + String(parseInt(_num) + 1).padStart(_num.length,'0');
        }
      }
    },
    cancelMst() {
      this.hiddenMstCd = '';
      this.prompt = false;
    },
    setMst() {
      this.prompt = false;
      let _order = 0;
      let cnt = this.mstGrid.data.length;
      if (cnt == 0) {
        _order = 1;
      } else if (cnt == 1) {
        _order = 10;
      } else {
        _order = this.mstGrid.data[cnt-1].sortOrder + 10;
      }
      this.mstGrid.data.push({
        editFlag: 'C',
        codeGrpCd: this.selectedGroupCd,
        code: this.hiddenMstCd,
        codeName: '',
        attrVal1: '',
        attrVal2: '',
        attrVal3: '',
        attrVal4: '',
        useFlag: 'Y',
        sortOrder: _order,
      })
      this.hiddenMstCd = '';
    },
    removeRow() {
      let selectData = this.$refs['mstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          type: 'warning', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.$format(this.mstDeleteUrl);
            this.$http.type = 'DELETE';
            this.$http.param = {
              data: Object.values(selectData)
            };
            this.$http.request(() => {
              // this.getCodeMst({codeGrpCd: selectData[0].codeGrpCd});
              window.getApp.$emit('APP_REQUEST_SUCCESS');
              
              this.$_.forEach(selectData, item => {
                this.mstGrid.data = this.$_.reject(this.mstGrid.data, item);
              })
              this.$refs['mstTable'].$refs['compo-table'].clearSelection();
            },);
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
  }
};
</script>
